/** antd 表格自定义样式 */
.mp-antd-table {
  /** 表头取消加粗 */
  .ant-table-thead > tr > th {
    background-color: #F3F3F3;
    font-weight: 400;
  }
  
  /** 表头列之间分割线 */
  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(
      .ant-table-row-expand-icon-cell
    ):not([colspan])::before {
    display: none;
  }

  /** padding */
  .ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td {
    padding: 10px 16px;
  }
}
