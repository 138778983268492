@import '@/var';
@import "./tailwindcss.scss";
@import "./antd-cover";

/** iconpark CDN 字体 https://iconpark.oceanengine.com/projects/23818/detail */
.iconpark-icon {
  width: 1em;
  height: 1em;
  font-size: 1em;
  vertical-align: middle;
  margin-top: -0.2em;
}
