@import '@/var';
.mp-user-aside {
  flex-basis: auto;
  .user-info-box {
    background-repeat: no-repeat;
    .aside-avatar {
      display: inline-block;
      width: 64px;
      height: 64px;
      border-radius: 50%;
      margin-bottom: 10px;
      overflow: hidden;
    }
  }

  .user-menu-link {
    display: flex;
    height: 54px;
    padding: 15px 30px 15px 116px;
    transition: all 0.2s;
    position: relative;

    &::before {
      display: none;
      position: absolute;
      top: 24px;
      left: 97px;
      content: "";
      width: 6px;
      height: 6px;
      background-color: #22cc76;
    }

    &.user-menu-link_active {
      background-color: #ebf8f1;
      &::before {
        display: block;
      }
    }
    &.user-menu-link_active,
    &:hover {
      color: #22cc76;
    }
  }
}

// 消息通知
.notify-container{
  .notify-tabs{
    width: 100% ;
    border-bottom: 1px #EFEFEF solid;
  }
  .notify-tab{
    border-bottom: transparent 2px solid;
    cursor: pointer;
    span{
      margin: auto;
      font-size: 12px;
      color: #494949;
    }
  }
  .notify-tab-on{
    border-bottom: #22cc76 2px solid;
    span{
      color: #22cc76;
    }
  }
  .notify-item{
    display: flex;
    padding: 24px 0;
    border-bottom: 1px #EFEFEF solid;
    align-items: center;
    .notify-item-content{
      width: 100%;
      color: #494949;
      font-size: 14px;
    }
    .notify-item-time{
      min-width: 180px;
      width: 180;
      max-width: 180px;
      margin-left: 26px;
      color: #909090;
      font-size: 14px;
    }
  }

  .no-notify-container{
    width: 100%;
    margin-top: 96px;
    // min-height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img{
      width: 150px;
      height: 150px;
      margin-bottom: 32px;
    }
    span{
      font-size: 18px;
      color: #494949;
    }
  }
}