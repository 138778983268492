@import '@/var';
.top-nav-link {
  color: #fff;
  position: relative;
  &::after {
    position: absolute;
    content: "";
    display: none;
    width: calc(100% - 10px);
    height: 4px;
    background: #22cc76;
    border-radius: 20px;
    bottom: -10px;
    left: 5px;
  }
  &.active {
    &::after {
      display: block;
    }
  }
}

.self-center{
  min-width: 92px;
}

.top-notify{
  position: relative;
  margin-left: 28px;
}
.notify-count{
  position: absolute;
  width: 32px;
  height: 18px;
  font-size: 12px;
  border-radius: 9px;
  background-color: rgb(255, 63, 86);
  text-align: center;
  top: -14px;
  left: 28px;
}

.notify99{
  position: absolute;
  width: 32px;
  height: 18px;
  top: -14px;
  left: 28px;
}