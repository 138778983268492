// @tailwind base;
@tailwind components;
@tailwind utilities;

/** tailwindcss 支持扩展的工具类 */
@layer utilities {
  /** 页面主体默认 1160px 宽度 */
  // .w-layout-main {
  //   width: 1160px;
  // }
}
