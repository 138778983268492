@import '@/var';
.ant-layout {
  background: #fff;
}
.ant-breadcrumb {
  a.ant-breadcrumb-link {
    color: #494949;
  }
  span.ant-breadcrumb-link {
    color: #1ebf6e;
  }
}
#editor {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  .w-e-toolbar {
    display: none;
  }
  .w-e-text-container {
    border: 0 !important;
    z-index: 1 !important;
    height: 100% !important;
    overflow-y: auto;
    .w-e-text {
      min-height: auto !important;
    }
  }
  .w-e-toolbar p,
  .w-e-text-container p,
  .w-e-menu-panel p {
    font-size: 14px !important;
  }
}

/** antd Modal 底部按钮居中 */
.ant-modal-footer {
  display: flex;
  justify-content: center;
}
