@import '@/var';
.footerContainer {
  text-align: center;
  padding: 22px;
  background: #f6f7fb;
  color: #acaeb0;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  span {
    cursor: pointer;
    &:hover {
      color: #1ebf6e;
    }
  }
}
