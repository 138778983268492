@import '@/var';
$fontFamily: "PingFang SC";

html,
body,
div,
ul,
ol,
li,
dl,
dt,
dd,
p,
span,
em,
strong,
b,
i,
form,
iframe,
table,
thead,
tbody,
input,
button,
video,
textarea,
select,
option,
img,
a,
xmp,
pre,
code,
h1,
h2,
h3,
h4,
h5,
h6,
canvas {
  margin: 0;
  padding: 0;
}
html {
  font-family: $fontFamily;
}
html,
body,
#page-container {
  width: 100%;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  color: #494949;
}
#page-container {
  flex: 1;
}

:root {
  --portrait-text: "";
}

/**
 * @description: margin padding 快捷设置
 */
// $directions: (
//   l: "left",
//   r: "right",
//   b: "bottom",
//   t: "top"
// );
// @for $size from 0 through 48 {
//   .m-#{$size} {
//     margin: #{$size}px;
//   }
//   .p-#{$size} {
//     padding: #{$size}px;
//   }
//   @each $dir, $direction in $directions {
//     .m#{$dir}-#{$size} {
//       margin-#{$direction}: #{$size}px;
//     }
//     .p#{$dir}-#{$size} {
//       padding-#{$direction}: #{$size}px;
//     }
//   }
// }

.iconfont {
  fill: currentColor;
}
img {
  object-fit: cover;
}
.bodyOverflow {
  overflow: hidden;
}

:root {
  --reader-global-bg: #F6F7FB;
  --reader-bg: #ffffff;
  --reader-text: #494949;
  --reader-btn-theme: #22CC76;
  --reader-btn-hover: #1ebf6e;
  --reader-btn-text: #ffffff;
  --reader-footer-text: #ACAEB0;
}