@import '@/var';
.headerMain {
  background: #171620;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.04);
  width: 100%;
  display: flex;
  justify-content: center;
  height: 64px;
  position: fixed;
  z-index: 10;
  .content {
    width: 1160px;
    margin: 0 32px;
    display: flex;
    align-self: center;
    .left {
      flex: 1;
      display: flex;
      min-width: 580px;
      img {
        width: 138px;
        height: 38px;
        margin-right: 64px;
      }
      .tab {
        font-size: 18px;
        font-weight: 400;
        color: #ffffff;
        line-height: 40px;
        cursor: pointer;
        margin-right: 30px;
        &.active {
          font-weight: bold;
          &::after {
            content: "";
            display: block;
            width: calc(100% - 10px);
            height: 4px;
            background: #22cc76;
            margin-top: -6px;
            margin-left: 5px;
            border-radius: 20px;
          }
        }
      }
    }
    .searchIpt {
      width: 240px;
      height: 36px;
      padding: 2px;
      background: #22cc76;
      border-radius: 17px;
      margin-right: 24px;
      :global {
        .ant-input-wrapper {
          .ant-input {
            border-radius: 17px 0 0 17px;
          }
          .ant-input-group-addon {
            border-radius: 0 17px 17px 0;
            background: #22cc76;
            button {
              border-radius: 0 17px 17px 0;
              background: #22cc76;
              border: 0;
              font-size: 16px;
              font-weight: bold;
              color: #494949;
              box-shadow: unset;
            }
            &:hover {
              background: #1ebf6e;
              button {
                background: #1ebf6e;
              }
            }
          }
        }
      }
    }
    .loginBtn {
      width: 64px;
      height: 36px;
      line-height: 34px;
      border: 1px solid #1ebf6e;
      text-align: center;
      border-radius: 20px;
      color: #22cc76;
      font-size: 14px;
      cursor: pointer;
    }
    .userBox {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-left: 12px;
      font-size: 14px;
      min-width: 172px;
      img {
        margin-right: 8px;
        width: 24px;
        height: 24px;
      }

      .username {
        color: #22cc76;
        padding-right: 8px;
        border-right: 1px dotted #929292;
      }
      .exitBtn {
        padding: 0 4px;
        margin: 0 4px;
        color: #4d8f6e;
        cursor: pointer;
      }
    }
  }
}
